<script setup>
import Header from "@/components/TheHeader.vue"
import Footer from "@/components/TheFooter.vue"
import TrainingModeBanner from "@/components/sites/TrainingModeBanner.vue"
import FlashMessage from "@/components/FlashMessage.vue"
import { setCurrentLanguage } from "@/services/Translation"
import { appendSha } from "@/utils/GlobalState"
import { appendSessionStatus } from "@/utils/Session"

appendSha()
appendSessionStatus()
setCurrentLanguage()
</script>

<template>
  <Header />
  <main>
    <TrainingModeBanner />
    <FlashMessage />
    <router-view v-slot="{ Component, route }">
      <transition
        :name="route.meta.transition"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

  <Footer />
</template>

<style lang="scss">
  @import "./assets/styles.scss";
</style>
